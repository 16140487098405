import React from 'react'
import Link from 'gatsby-link'
import Layout from '../../components/Layout'

export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section section--gradient">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <h2 className="title is-size-3 has-text-weight-bold is-bold-light">Descargas</h2>
                  <div className="content">
                    <p>
                      Todas nuestras correcciones de tests funcionan sobre el mismo software.
                    </p>
                    <p>
                      IntelTest es una base de datos que te permitirá almacenar información de todos tus evaluados en un mismo lugar. Los datos son guardados de manera segura en tu computador y nunca son enviados a nuestros servidores.
                    </p>

                    <h2>Instalación o Actualización</h2>
                    <h3>Windows 7 o superior (funciona para 32 y 64 bits):</h3>
                    <ul>
                      <li>
                      <a href="https://www.dropbox.com/s/3859e7n1a412svo/IntelTest-Instalador.exe?dl=1" target="_BLANK" download>IntelTest-Instalador.exe (instalador en Microsoft Windows)</a>
                      </li>
                      <li>
                      <a href="https://www.dropbox.com/s/v15wiady97b3r23/IntelTest-Instalador.zip?dl=1" target="_BLANK" download>IntelTest-Instalador.zip (Versión comprimida, para navegadores que rechazan la descarga)</a>
                      </li>
                    </ul>
                    <p>Descargue y abra el archivo IntelTest-Instalador.exe. La instalación se realizará automáticamente. Tras finalizar, encontrará la aplicación en su Menú de Inicio.</p>
                    <h3>Mac OSX:</h3>
                    <ul>
                      <li>
                        <a href="https://www.dropbox.com/s/fib99k17f6ixk3e/IntelTest.dmg?dl=1" target="_BLANK" download>IntelTest.dmg (instalador en Mac OSX)</a>
                      </li>
                    </ul>
                    <p>Descargue y abra el archivo IntelTest.dmg. A continuación, arrastre la App IntelTest a su carpeta de aplicaciones.</p>
                    <h4>Notas</h4>
                    <p>
                      Algunos navegadores o antivirus pueden rechazar la descarga. Si esto ocurre, prefiera el archivo .zip. Nuestro software no contiene virus ni malware. Esto es un comportamiento normal de Windows con programas que no pertenecen a una empresa reconocida.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

/*
<p>
  This can be caused by the offline-plugin.{' '}
  <a href="https://github.com/gatsbyjs/gatsby/issues/7997#issuecomment-419749232">
    Workaround
  </a>{' '}
  is to use <code>?no-cache=1</code> in the POST url to prevent
  the service worker from handling form submissions
</p>
<h3>Adding reCAPTCHA</h3>
<p>
  If you are planning to add reCAPTCHA please go to{' '}
  <a href="https://github.com/imorente/gatsby-netlify-form-example">
    imorente/gatsby-netlify-form-example
  </a>{' '}
  for a working example.
</p>
*/